<template>
  <div class="container_tabla">
    <BaseVisualizadorFotos ref="visualizadorFotos" />

    <b-modal id="modal-historico" ref="modal-historico" size="xl" centered title="Histórico de compras" hide-footer>
      <b-table hover sticky-header head-variant="light" :items="itemsHistorico"></b-table>
    </b-modal>

    <b-table id="tabla-productos" :items="items" :fields="fields" :busy="isBusy" bordered hover
      :small="size" head-variant="light" selectable select-mode="single" show-empty sticky-header
      :tbody-tr-class="rowClass" @row-selected="onRowSelected">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="spinner"></b-spinner>

          <strong>Cargando...</strong>
        </div>
      </template>

      <template #empty>
        <h4>Sin resultados...</h4>
      </template>

      <template #cell(precio)="data">
        {{ data.item.precio | currency}}
      </template>

      <template #cell(cantidad)="data">
        <b-form-input :ref="`item-tabla-${data.item.idx}`" placeholder="0" v-model="data.item.cantidad"
          v-on:focus.native="onFocus(data.item)" />
      </template>

      <template #cell(historico)="data">
        <b-button class="button-camera" @click="mostrarHistorico(data)">
          <b-icon icon="clock-history"></b-icon>
        </b-button>
      </template>

      <template #cell(precioEspecial)="data">
        <b-form-input placeholder="0" v-model="data.item.precioEspecial" v-on:focus.native="onFocus(data.item)" />
      </template>

      <template #cell(precioIva)="data">
        {{ calcularPrecio(data) | currency }}
      </template>

      <template #cell(imagen)="data">
        <b-button class="button-camera" v-if="data.item.tieneFoto" @click="mostrarFotos(data)">
          <b-icon icon="card-image"></b-icon>
        </b-button>
      </template>
    </b-table>
  </div>
</template>
<script>
import moment from "moment";
import BaseVisualizadorFotos from "./_BaseVisualizadorFotos.vue";
import { axiosInstance } from '../../utils/axiosInstance';
export default {
  components: { BaseVisualizadorFotos },
  name: "tabla",
  data() {
    return {
      productoSeleccionado: {},
      itemsHistorico: []
    };
  },
  mounted() {
    document.addEventListener("keydown", this.nextItem);
  },
  props: [
    "items",
    "fields",
    "isBusy",
    "rows",
    "size",
    "mostrarImagen",
    "cliente"
  ],
  methods: {
    onRowSelected(item) {
      this.$emit("row-selected", item);
      this.productoSeleccionado = item[0];
    },
    onFocus(item) {
      this.productoSeleccionado = item;
    },
    nextItem(e) {
      if (e.keyCode == "38" && this.productoSeleccionado.idx > 0)
        this.$refs[`item-tabla-${this.productoSeleccionado.idx - 1}`].focus();
      if (e.keyCode == "40")
        this.$refs[`item-tabla-${this.productoSeleccionado.idx + 1}`].focus();
    },
    makeToast(title = null, body = null, variant = null, duration = 1000) {
      body = body || 'Toast body content';
      this.$bvToast.toast(body, {
        title: title || 'default',
        toaster: 'b-toaster-top-center',
        variant: variant || 'default',
        autoHideDelay: duration,
        solid: true
      });
    },
    mostrarFotos({ item }) {
      this.$refs.visualizadorFotos.getRutasImagenes(item);
    },
    mostrarHistorico({ item }) {
      this.productoSeleccionado = item;

      if (!this.cliente) {
        this.makeToast('Histórico', 'Debe seleccionar un cliente para obtener su histórico', 'error', 2000);
        return;
      }

      axiosInstance.get('pedidos/historico', {
        params: { producto_id: this.productoSeleccionado.producto_id, cliente: this.cliente },
        headers: {
          authorization: localStorage.getItem('token')
        }
      })
        .then(({ data }) => {
          this.itemsHistorico = data.map(pedido => {
            return {
              fecha: moment(pedido.creacion).format('DD/MM/YYYY'),
              articulo: pedido.pedido_productos[0].producto.descripcion,
              cantidad: pedido.pedido_productos[0].cantidad,
              pedido: pedido.codigo,
              precio: pedido.pedido_productos[0].precio,
              factura: pedido.Factura?.NumFactura || '',
              facturadas: pedido.Factura?.factura_productos[0].CantidadSurtida || '',
              _rowVariant: pedido.pedido_productos[0].oferta ? 'danger' : '',
            }
          });

          this.$refs["modal-historico"].show();
        })
        .catch(() => {
          this.makeToast('Histórico', 'Hubo un error al obtener el histórico', 'error', 2000);
        })
    },
    calcularPrecio({ item }) {
      const { iva, precio } = item;
      if (iva === 0) {
        return precio;
      }
      return precio + precio * iva;
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.tieneOferta) return "oferta-row";
      if (item.tienePrecioEspecial) return "precioEspecial-row";
      if (moment().diff(item.fechaPrecio, "days") < 30)
        return "precio-actualizado-row";
      if (item.esNuevo) return "producto-nuevo-rrefow";
      if (item.sobrepedido) return "producto-sobrepedido";
    },
  },
  filters: {
    currency: function (value) {
      try {
        return (
          "$" +
          value
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
      } catch {
        return "-";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
table {
  margin-top: 0px;
  height: 80vh !important;
}

.spinner {
  margin-right: 10px;
}

.b-table-sticky-header {
  max-height: 80vh !important;
  min-height: 80vh !important;
  height: 100%;
}

.container_tabla {
  padding: 0px !important;
}

.oferta-row {
  background-color: #ff474e !important;
}

.precioEspecial-row {
  background-color: #ffc6d9 !important;
}

.precio-actualizado-row {
  background-color: #aad576 !important;
}

.producto-nuevo-row {
  background-color: #b0d9ff !important;
}

.producto-sobrepedido {
  background-color: #ffd670 !important;
}

.button-camera {
  padding: 0 3px !important;
}
</style>